import encryptStorage from '../../utils/storage';
import { buildRequestOptions, handleResponse } from '../../utils';

export const fetchSubscriptionsBySubscriptionId = (subscriptionId) => async (dispatch) => {
  dispatch({ type: 'FETCH_SUBSCRIPTIONS_START' });

  try {
    const wpID = encryptStorage.getItem('wpID');
    if (!wpID) {
      console.error("Missing WordPress ID");
      throw new Error("Missing WordPress ID");
    }

    const requestOptions = buildRequestOptions('GET');
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/subscriptions/${subscriptionId}`, requestOptions);
    if(response.status === 404) {
      dispatch({ type: 'FETCH_SUBSCRIPTIONS_FAILURE', error: 'Subscription not found' });
      return;
    }
    const subscriptionData = await handleResponse(response, dispatch, 'FETCH_SUBSCRIPTIONS_FAILURE');

    if (subscriptionData) {
      const subscriptions = Array.isArray(subscriptionData) ? subscriptionData : [subscriptionData];
      dispatch({ type: 'FETCH_SUBSCRIPTIONS_SUCCESS', payload: subscriptions });
    }
  } catch (error) {
    console.error('Error fetching subscriptions:', error);
  }
};

export const fetchSubscriptionsByProductCodes = (productCode) => async (dispatch) => {
  dispatch({ type: 'FETCH_SUBSCRIPTIONS_BY_CODES_START' });
  
  try {
    const wpID = encryptStorage.getItem('wpID');
    if (!wpID) {
      throw new Error("Missing WordPress ID");
    }

    const requestOptions = buildRequestOptions('POST', [productCode]);
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/subscriptions/list`, requestOptions);
    if(response.status === 404) {
      dispatch({ type: 'FETCH_SUBSCRIPTIONS_BY_CODES_FAILURE', error: 'Subscription not found' });
      return;
    }
    let subscriptions = await handleResponse(response, dispatch, 'FETCH_SUBSCRIPTIONS_BY_CODES_SUCCESS', 'FETCH_SUBSCRIPTIONS_BY_CODES_FAILURE');

    if (subscriptions) {
      subscriptions = subscriptions.filter((sub) => sub.status !== 'inactive');
      dispatch({ type: 'FETCH_SUBSCRIPTIONS_BY_CODES_SUCCESS', payload: subscriptions });
      dispatch({ type: 'ADD_FETCHED_SKU', payload: productCode });
    }
  } catch (error) {
    console.error('Error:', error);
  }
};

export const updateSubscription = (id, email, firstName, lastName, company) => ({
  type: 'UPDATE_SUBSCRIPTION_USER_INFO',
  payload: { id, email, firstName, lastName, company }
});

export const updateSubscriptionDatabaseInfo = (id, dbName) => ({
  type: 'UPDATE_SUBSCRIPTION_DATABASE_INFO',
  payload: { id, dbName }
});
